@import "../config/config.scss";

// Blog List

.countryList .imageWrapper {
  position: relative;
  text-align: center;
}
.countryList .countryHeader {
  background: rgba(253, 253, 255, 0.74);
  font-family: $headline;
  font-size: 55px;
  padding-bottom: 10px;
}
@media (max-width: 1000px) {
  .countryList .countryHeader {
    font-size: 35px;
  }
}

.countryList .imageWrapper img {
  width: 100%;
  height: auto;
}
.countryList .blogEntry {
  font-family: $main;
}
.countryList .blogEntry .link {
  text-decoration: none;
  color: $secondary-color;
}
.countryList .blogEntry h2 {
  margin-bottom: 0;
  font-family: $headline;
}
.countryList .blogEntry h2:hover {
  color: lighten($secondary-color, 50%);
}
.countryList .blogEntry .date {
  font-family: $date;
  font-size: 15px;
  text-align: right;
}
