@import "../config/config.scss";

.gal .container h1 {
  background: rgba(253, 253, 255, 0.74);
  font-family: $headline;
  font-size: 55px;
  padding-bottom: 10px;
}
@media (max-width: 1000px) {
  .gal .container h1 {
    font-size: 35px;
  }
}

.gal .gallery {
  display: grid;
  grid-gap: 20px;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  font-size: 13px;
  img {
    width: 100%;
    cursor: pointer;
    opacity: 0.9;
    &:hover {
      opacity: 1;
    }
  }
  // .commentImg {
  //   padding: 0;
  //   margin: 0;
  //   line-height: 15px;
  // }
}

.gal .overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.9);
  overflow: auto;
  display: none;
  z-index: 4;
  .image {
    align-self: center;
    justify-self: center;
    width: 60%;
    img {
      max-width: 100%;
    }
    .text {
      color: darken(white, 20%);
      font-size: 14px;
      line-height: 16px;
    }
  }
  .arrowLeft {
    position: fixed;
    left: 5vw;
    top: 40%;
    bottom: 50%;
    .left {
      width: 0;
      height: 0;
      border-top: 50px solid transparent;
      border-bottom: 50px solid transparent;

      border-right: 50px solid darken(white, 20%);
      cursor: pointer;
    }
  }
  .arrowRight {
    position: fixed;
    right: 5vw;
    top: 40%;
    bottom: 50%;
    .right {
      width: 0;
      height: 0;
      border-top: 50px solid transparent;
      border-bottom: 50px solid transparent;

      border-left: 50px solid darken(white, 20%);
      cursor: pointer;
    }
  }
  .close {
    position: fixed;
    right: 20px;
    bottom: 20px;
    color: darken(white, 20%);
    font-size: 15px;
    cursor: pointer;
  }
  .count {
    position: fixed;
    right: 20px;
    top: 20px;
    color: darken(white, 20%);
    font-size: 15px;
  }
}
.gal .overlay.shown {
  display: grid;
}
@media (max-width: 1000px) {
  .gal .overlay {
    .arrowRight {
      right: 3vw;
      .right {
        border-top: 50px solid transparent;
        border-bottom: 50px solid transparent;
        border-left: 40px solid darken(white, 0%);
      }
    }
    .arrowLeft {
      left: 3vw;
      .left {
        border-top: 50px solid transparent;
        border-bottom: 50px solid transparent;
        border-right: 40px solid darken(white, 0%);
      }
    }
    .image {
      width: 100%;
      display: grid;
      img {
        align-self: center;
        justify-self: center;
      }
    }
    .text {
      text-align: center;
    }
  }
}
