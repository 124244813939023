@import "../config/config.scss";

header {
  width: 100%;
  font-family: "Oswald", sans-serif;
  background: $fourth-color;
  -webkit-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.75);
  z-index: 2;
  position: relative;
}

// header .upper {
//   text-align: center;
//   background: $primary-color;
// }
header .logo {
  color: $third-color;
  padding-left: 15px;
  padding-top: 6px;
  padding-bottom: 9px;
  font-size: 20px;
  position: relative;
  font-family: $main;
}
// @media (max-width: 500px) {
//   header .logo {
//     font-size: 30px;
//     line-height: 50px;
//   }
// }
// @media (max-width: 350px) {
//   header .logo {
//     font-size: 20px;
//     line-height: 60px;
//   }
// }
header .logo .wrapper {
  display: inline-block;
}
header .logo .wrapper .link {
  text-decoration: none;
  color: inherit;
}
