form {
  text-align: left;
}

form label {
  font-weight: bold;
  margin-right: 20px;
}
// form .gridWrapper {
//   display: grid;
//   grid-template-columns: 1fr 1fr;
// }
form .gridWrapper {
  padding: 30px;
}
form .gridWrapper div {
  padding: 10px;
}
